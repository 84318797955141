import React from 'react';
import { Link } from 'gatsby';

import { Layout, SEO, Hero, ContactUs, LeaveAMessage } from '../components';
import { useGraphQL } from '../hooks';

function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact" />
      <HeroSection />
      <ContactUs />
      <LeaveAMessage />
    </Layout>
  );
}

function HeroSection() {
  const { contactHero } = useGraphQL();
  return (
    <Hero image={contactHero.childImageSharp.fluid}>
      <div className="w-full space-y-4 text-center">
        <h1 className="text-white heading-1">Contact us</h1>
        <Link to="/" className="text-white button">
          Want to learn about us?
        </Link>
      </div>
    </Hero>
  );
}

export default ContactPage;
